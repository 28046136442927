*{
    box-sizing: border-box;
}

.big-navbar{
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 100%;
    padding: 0px 10px;
    position: fixed;
    width: 100%;
    height: 100px;
    background-color: #fff;
    z-index: 99999;

}
.logo-navbar img{
    width: 200px;
    cursor: pointer;
}
.text-navbar{
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 30px;
    font-size: 12px;
    font-family: "Oswald", sans-serif;
    font-weight: 550;
    color: #000;
}
.text-navbar p:hover{
    color: #821213;
    cursor: pointer;
}
.text-navbar a{
    text-decoration: none;
    color: #000;
}
.login-navbar{
    background-color: #000;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 15px;
    font-weight: 600;
    border-radius: 30px;
    padding: 15px 5px 0px 15px;
    width: 100px;
    height: 40px;
    cursor: pointer;
}
.login-navbar:hover{
    background-color: #821213;
}
.LuLogIn-navbar{
    font-weight: 700;
    width: 20px;
    height: 20px; 
    border-radius: 50%;
    border: 1px solid white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mediya-navbar{
    display: none;
}
.SlMenu-navbar{
    font-size: 30px;
    border: 1px solid #000;
    border-radius: 5px;
    padding: 0px 5px 5px 5px;
}
.media-text-navbar{
    display: none;
    font-family: "Oswald", sans-serif;
    background-color: #f0f0f0;
    height: auto;
    width: 320px;
    position: absolute;
    top: 100px;
    right: 0px;
    padding: 10px 0px 0px 0px;
    z-index: 9999;
}
.media-text-navbar a{
    color: #000;
    text-decoration: none;
}
.media-text-navbar1{
    display: none;
    font-family: "Oswald", sans-serif;
    background-color: #f0f0f0;
    height: auto;
    width: 220px;
    position: absolute;
    top: 100px;
    right: 0px;
    padding: 10px 0px 0px 0px;
    z-index: 9999;
}
@media (max-width:425px) {
    .media-text-navbar{
        width: 100%;
    }
    .media-text-navbar1{
        width: 100%;
    }
}
.media-text-navbar p{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    width: 90%;
    margin: auto;
    margin-bottom: 10px;
    height: 35px;
    border-radius: 5px;
    cursor: pointer;
}
.media-text-navbar1 span{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;top: 0;
    border-right: 1px solid #ddd;
    color: #821213;
    height: 100%;
    width: 30px;
}
.media-text-navbar p:hover{
    color: #821213;
}
.media-text-navbar1 p{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    width: 90%;
    margin: auto;
    margin-bottom: 10px;
    height: 35px;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
}
.media-text-navbar1 p:hover{
    background: #821213;
    color: #fff;
}
.media-text-navbar1 p:hover span{
    background-color: #fff !important;
}
@media (max-width:1100px) {
    .logo-navbar img{
        width: 180px;
    }
    .text-navbar{
        display: flex;
        align-items: center;
        justify-content: space-around;
        gap: 20px;
        font-size: 11px;
        font-family: "Oswald", sans-serif;
        font-weight: 550;
    }
    .login-navbar{
        background-color: #000;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0;
        font-size: 13px;
        font-weight: 600;
        border-radius: 30px;
        padding: 15px 5px 0px 15px;
        width: 100px;
        height: 40px;
    }
}
@media (max-width:1000px) {
    .IoPerson-navbar1{
        border: 1px solid #000;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
    }
    .my-account-check-open{
        display: none;
    }
    .mediya-navbar{
        display: flex;
        align-items: center;
        gap: 30px;
    }
    .LuLogIn-navbar{
        font-size: 25px;
        font-weight: 700;
        width: 40px;
        height: 40px; 
        border-radius: 50%;
        border: 1px solid black;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .text-navbar{
        display: none;
    }
    .login-navbar{
        display: none;
    }
}
.open-media-text-navbar{
    display: block;
}
.open-media-text-navbar1{
    display: block;
}

.df-my-account-div{
    display: flex;
    gap: 10px;
    padding: 10px 10px 10px 20px;
    border-radius: 30px;
    border: 2px solid #000;
    margin-top: 5px;
    cursor: pointer;
}
.df-my-account-div p{
    margin-bottom: 0px;
    font-weight: 600;
    margin-top: 0px;
}
.name-my-account{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}
.name-my-account p{
    font-weight: 600;
    margin-bottom: 0;
} 