.big-div-my-alert h1{
    color: #821213;
    font-family: "Arimo", sans-serif; 
    font-weight: 600;
    text-align: center;
    font-size: 40px;
    padding-top: 150px;
}
.big-div-my-alert h3{
    color: #000000;
    font-family: "Arimo", sans-serif; 
    font-weight: 550;
    text-align: center;
    margin-bottom: 200px;
}
.big-div-my-alert b{
    color: #821213;  
}