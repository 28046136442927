.big-div-inside{
    text-align: center;
    padding-top: 100px;
    width: 90%;
    margin: auto;
}
.big-div-inside h1{
    font-weight: 600;
    color: #821213;
    font-family: "Arimo", sans-serif;
}
.big-div-inside h3{
    font-weight: 600;
    color: #000;
    font-family: "Arimo", sans-serif;
}
.big-div-inside p{
    color: #000;
    width: 90%;
    margin: auto;
    font-family: "Arimo", sans-serif;
    margin-top: 30px;
}
.big-div-inside img{
    width: 80%;
    margin-bottom: 200px;
    margin-top: 50px;
}

@media (max-width: 600px) {
    .big-div-inside img{
        width: 100%;
        margin-bottom: 100px;
    }
}

.ok-classic-inside{
    width: 300px !important;
}