    *{
    box-sizing: border-box;
}

.header-home{
    background: url("../img/classiccar-header.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.find-car-home{
    width: 70%;
    background-color: #ffffffd2;
    border-radius: 10px;
    padding: 0px 10px 0px 10px;
    font-family: "Arimo", sans-serif;    
}
.find-your-classic{
    font-size: 20px;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 0px;
}

.input-text-home p{
    font-size: 10px;
    color: #666666;
    font-family: "Arimo", sans-serif;
    margin-bottom: 2px;
}

.input-text-home input:focus{
    box-shadow: 0px 0px 5px #007bff;
}
.searching-car-home{
    display: flex;
    align-items: center;
    width: 100%;
    gap: 10px;
}

.input-div-home{
    width: 40%;
    display: flex;
    align-items: center;
    gap: 10px;
}
.input-text-home{
    width: 100%;
}
.input-text-home input{
    border: none;
    border-radius: 5px;
    outline: none;
    width: 100%;
    height: 30px;
    max-width: 300px;
    color: #666666;
    font-size: 15px;
}

.selec-div-home{
    display: flex;
    align-items: center;
    gap: 10px;
    width: 60%;
}

.select-text-home p{
    font-size: 10px;
    color: #666666;
    font-family: "Arimo", sans-serif;
    margin-bottom: 2px;
}

.select-text-home{
    width: 50%;
}

.select-text-home select{
    border: none;
    border-radius: 5px;
    max-width: 600px;
    width: 100%;
    height: 30px;
    color: #666666;
    outline: none;
}

.select-text-home select:focus{
    box-shadow: 0px 0px 5px #007bff;
}

.add-own-searching-home{
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #821213;
    font-size: 15px;
    font-weight: 500;
    margin-top: 10px;
}
.add-own-searching-home p{
    cursor: pointer;
    font-family: "Arimo", sans-serif;
    font-weight: 550;
}
.advertisement-home{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100px;
    color: #fff;
    background-color: #000;
    font-size: 30px;
    font-weight: 600;
    font-family: "Arimo", sans-serif;
    padding: 0px 10px;
    text-align: center;
    cursor: pointer;
}
.advertisement-home:hover{
    color: #a0a0a0;
}

@media (max-width:800px) {
    .searching-car-home{
        display: block;
    }
    .input-div-home{
        width: 100%;
    }
    .selec-div-home{
        display: block;
        width: 100%;
    }
    .select-text-home{
        width: 100%;
    }

}
@media (max-width:500px) {
    .find-car-home{
        width: 95%;
    }
    .advertisement-home{
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 100px;
        color: #fff;
        background-color: #000;
        font-size: 20px;
        font-weight: 600;
        font-family: "Arimo", sans-serif;
        padding: 0px 10px;
        text-align: center;
    }
    .add-your-car-to-sell h1, .add-your-car-to-sell h1{
        font-size: 17px !important;
    }
    .add-your-car-to-sell h5, .add-your-car-to-sell h5{
        font-size: 12px !important;
    }
}

.reklama-home{
    width: 90%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
}



.first-section-home{
    width: 100%;
    margin: auto;
    display: flex;
    gap: 3%;
    padding: 0px 0px 0px 10px;
}

.accordion-home{
    width: 25%;
}
.view-sells-car-home{
    width: 100%;
}
.add-your-car-to-sell, .add-your-car-to-sell1{
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #821213;
    font-family: "Arimo", sans-serif;
    margin-bottom: 0px;
    padding: 0px 30px 0px 0px;
}

.add-your-car-to-sell1{
    margin-top: 20px;
}

.add-your-car-to-sell h1, .add-your-car-to-sell1 h1{
    cursor: pointer;
    font-size: 20px;
    font-weight: 600;
    font-family: "Arimo", sans-serif; 
}
.add-your-car-to-sell h5, .add-your-car-to-sell1 h5{
    cursor: pointer;
    font-size: 13px;
    font-weight: 600;
    font-family: "Arimo", sans-serif; 
}
.accordion-home p{
    height: 20px;
    margin-bottom: 10px;
}
@media (max-width:500px) {
    .accordion-home p{
        background-color: #333;
        color: #fff;
        width: auto;
        border-radius: 10px;
        font-weight: 600;
        font-family: "Arimo", sans-serif; 
        padding: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .accordion-body{
        display: flex !important;
        align-items: center;
        gap: 15px;
        flex-wrap: wrap !important;
    }
}
.accordion-header{
    font-family: "Oswald", sans-serif !important;
}
.accordion-button::after{
    display: none !important;
}
.accordion-home p:hover{
    color: #821213;
    cursor: pointer;
}
.accordion-button{
    background-color: #f2f2f2 !important;
    box-shadow: 0px 0px 0px #fff !important;
    color: #000;
    font-weight: 600 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
}
.accordion-button:hover{
    color: #821213 !important;
}
.accordion-button:first-of-type {
    background-color: #f2f2f2 !important;
    box-shadow: 0px 0px 0px #fff !important;
    color: #000;
    font-weight: 600 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
}
.accordion-item:first-of-type .accordion-button{
    background-color: #f2f2f2 !important;
    box-shadow: 0px 0px 0px #fff !important;
    color: #000;
    font-weight: 600 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
}

.accordion-item:first-of-type:hover .accordion-button:hover, .accordion-item:last-of-type .accordion-button:hover, .collapsed:hover{
    color: #821213 !important;
}
.accordion-item:last-of-type .accordion-button, .collapsed{
    background-color: #f2f2f2 !important;
    box-shadow: 0px 0px 0px #fff !important;
    color: #000;
    font-weight: 600 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
}
.accordion{
    box-shadow: 0px 0px 5px #666 !important;
    border-radius: 10px;
}
.accordion-body{
    background: #f2f2f2 !important;
    padding: 10px !important;
}
.featured-home{
    font-weight: 550;
    margin-top: 20px;
}

.big-cars-div-home{
    display: flex;
    align-items: start;
    gap: 10px; 
    flex-wrap: wrap;
    /* display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px; */
}
@media (max-width:1306px) {
    .big-cars-div-home{
     justify-content: space-around;
    }
    /* .cars-div-home{
        width: 211px !important;
    } */
}
.cars-div-home{
    cursor: pointer;
    width: 244px;
    min-height: 330px;
    height: max-content;
    margin-top: 20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0px 0px 5px #666666;
    overflow: hidden;
    padding: 0px 0px 10px 0px;
    position: relative;
}

.cars-div-home img{
    width: 100%;
    height: 150px;
}
.title-home{
    padding: 5px 5px;
    margin-top: 0;
    color: #000000;
    font-size: 16px;
    text-align: start !important;
    font-family: "Arimo", sans-serif;
    font-weight: 600;
}
.discreaption-home{
    padding: 0px 5px;
    color: #a0a0a0;
    margin-top: 0;
    font-size: 14px;
    text-align: start !important;
    /* height: 30px ; */
    margin-top: 20px;
}
.price-car-home{
    padding: 5px 5px 5px 10px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    color: #821213;
    font-size: 25px;
    font-weight: 550;
    position: absolute;
    bottom: 5px;
    right: 5px;
}

.price-car-sale-title{
    color: #a0a0a0;
    font-size: 14px;
    text-decoration: line-through;
}

.sale-block{
    position: absolute;
    top: 0;
    right: 0;
    background-color: #821213;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-left: 2px solid #fff;
    border-bottom: 2px solid #fff;
    padding: 5px 10px;
    color: #fff;
    font-weight: 600;
}


.cars-div-home1 img{
    width: 100%;
    height: 150px !important;
}

.swiper {
    width: 100%;
    height: auto;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .swiper {
    margin-left: auto;
    margin-right: auto;
  }
  .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal{
    display: none !important; 
  }

  .swiper-button-prev, .swiper-rtl .swiper-button-next{
    width: 35px !important;
    height: 35px !important;
    color: #333 !important;
    font-size: 0px !important;
    background-color: #dddddd96;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
  .swiper-button-next, .swiper-rtl .swiper-button-prev{
    width: 35px !important;
    height: 35px !important;
    color: #333 !important;
    background-color: #dddddd96;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
  .swiper-button-prev:after, .swiper-rtl .swiper-button-next:after{
    font-size: 15px !important;
    font-weight: 600 !important;
  }
  .swiper-button-next:after, .swiper-rtl .swiper-button-prev:after{
    font-size: 15px !important;
    font-weight: 600 !important;
  }

  .cars-div-home1{
    cursor: pointer;
    width: 244px;
    min-height: 330px;
    height: auto;
    margin-top: 20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0px 0px 5px #666666;
    overflow: hidden;
    padding: 0px 0px 10px 0px;
    position: relative;
}

.mySwiper{
    display: none !important;  
}
@media (max-width:1200px) {
    .mySwiper{
        display: block !important;  
    }
    .cars-div-home{
        display: none !important;
    }
    .first-section-home{
        display: block;
    }
    .accordion-home{
        width: 100%;
    }
    .view-sells-car-home{
        width: 100% !important;
        margin-top: 20px;
    }
}



#special-for-this-option-select1{
    color: #666 ;
    font-size: 15px;
    width: 80%;
    margin: auto;
}
#special-for-this-option-select{
    color: #000;
    font-size: 13px ;
    padding: 20px;
}
#special-for-this-option-select:hover{
    background: #fff;
}