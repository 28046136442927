.body{
    width: 90%;
    max-width: 1400px;
    margin: auto;
}
.imgg{
    background: url("https://classiccars.com/img/bg-images/6.jpg?_vCache=638485040700000000")no-repeat;
    background-size: cover;
    min-height: 550px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-position: center;
    padding: 10px;
}
.black h1{
    font-size: 40px;
    text-align: center;
    margin-top: 65px;
    font-family: "Arimo", sans-serif;
    font-weight: 550;
}
.black span{
    font-size: 25px;
    font-family: "Arimo", sans-serif;
    font-weight: 550;
}
.black{
    padding-top: 100px;
    width: 100%;
    height: 400px;
}
.clascar{
    margin-top:40px ;
    width: 60%;
    margin-left: 250px;
}
.pitch{
    margin-top: 50px;
    min-width: 300px;
    width: 99%;
}
.dealer{
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: start;
    margin-top: 50px;
}
.setting{
    display: grid;
    grid-template-columns: 30px auto;
    gap: 10px;
    margin-top: 10px;
}
.setting h3{
    font-size: 18px;
    font-weight: 550;
    font-family: "Arimo", sans-serif;
}
.new{
    background-color: #006b81;
    padding: 10px 0px;
    width: 10%;
    font-size: 15px;
    border-radius: 20px;
    color: white;
    font-weight: 900;
    text-align: center;
}
/* .newfeature{
    display: flex;
    align-items: center;

} */
.new2{
    background-color: #006b81;
    padding:8px 0px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 250px;
    font-size: 15px;
    border-radius:50%;
    color: white;
    font-weight: 900;
    max-width: 200px;
    text-align: center;
}
.set{
    font-size: 30px;
    transition: .4s;
    margin-top: -4px;
}
.setting:hover{
    .set{
        color:#821213;
    }
}

.request{
    text-align: center;
    font-size: 35px;
    margin-top: 50px;
    font-weight: 500;
}
.input_big{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    border-radius: 7px;
    padding: 10px;
    justify-content: center;
    margin-bottom: 30px;
    box-shadow: 1px 1px 5px #a9a9a9;
    background-color: #f9f9f9 ;
    margin-top: 30px;
}
.name{
    margin-bottom: 30px;
}
.name input{
    width: 90%;
    height: 40px;
    border-radius: 5px;
    outline: none;
    border: 1px solid #ced4da;
    margin-top: 6px;
    margin-bottom: 10px;
}

.name label{
    margin-left: 5px;
}
.btn1{
    display: flex;
    justify-content: end;
    align-items: end;
    width: 90%;
}
.btn1 button{
    width:60%;
    border: none;
    background-color: black;
    height: 40px;
    color: white;
    font-size: 20px;
    font-weight: 600;
    border-radius: 7px;
}
.btn1 button:hover{
    background-color: #821213;
}
@media (max-width:1340px) {
    .collector{
        display: flex;
        width: 99%;
        margin: auto;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .move{
        width: 90%;
    }
    .move1{
        width: 90%;
    }
}
@media (max-width:1160px) {
    .dealer{
        display: grid;
        grid-template-columns: 1fr ;
        align-items: start;
    }
    .clascar{
        margin-top:40px ;
        width: 70%;
        margin-left: 150px;
    }
    .hunter{
         margin-top: 30px;
    }
    .intro{
        margin-top: 20px;
    }
}
@media (max-width:1010px) {
    .dealer{
        display: grid;
        grid-template-columns: 1fr ;
        align-items: start;
    }
    .clascar{
        margin-top:40px ;
        width:99%;
        margin-left: 0px;
    }
}
@media (max-width:1000px) {
    .input_big{
        display: grid;
        grid-template-columns: 1fr 1fr ;
        /* background-color: #d3d3d3 ; */
        border-radius: 7px;
        padding: 10px;
        margin-bottom: 30px;
        justify-content: space-around;
        box-shadow: 1px 1px 5px #a9a9a9;
    }
    
}
@media (max-width:630px) {
    .black h1{
        font-size: 29px;
        text-align: center;
        
    }
    .black{
        padding-top: 10px;
        width: 100%;
        height: 200px;
    }
    .imgg{
        background: url("https://classiccars.com/img/bg-images/6.jpg?_vCache=638485040700000000")no-repeat;
        background-size: cover;
        min-height: 400px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        background-position: center;
    }
    .black span{
        font-size: 21px;
    }
    .input_big{
        display: grid;
        grid-template-columns: 1fr ;
        /* background-color: #d3d3d3 ; */
        border-radius: 7px;
        padding: 10px;
        margin-bottom: 30px;
        justify-content: space-around;
        box-shadow: 1px 1px 5px #a9a9a9;
    }
    .name input{
        width: 99%;
        height: 40px;
        border-radius: 10px;
        outline: none;
        border: 1px solid grey;
        margin-top: 6px;
        margin-bottom: 40px;
    }
    .btn1 button{
        width: 100%;
    }
    .btn1{
        width: 99%;
    }
}
@media (max-width:600px) {
    .new{
        display: none;
    }
    .com{
        font-size: 25px;
    }
}
@media (max-width:400px) {
    .clascar{
        display: none;
    }
    .pitch{
        display: none;
    }
    .com{
        font-size: 20px;
    }
}
.df1{
    display: flex;
    align-items: start !important;
    gap: 10px;
}
.df1 h1{
    font-size: 25px;
    font-family: "Arimo", sans-serif;
    font-weight: 550;
}
.fast{
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.checkt{
    display: flex;
    align-items: start;
    gap: 10px;
    margin-top: 15px;
}
.checkt h3{
    font-size: 20px;
    font-family: "Arimo", sans-serif;
    font-weight: 550;
    width: 80%;
}
.df1 span{
    color: #821213;
}
.online{
    width: 100%;
    margin-bottom: 20px;
}
.circlech{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    color: #821213;
}
.intro{
    background-color: white;
    border-radius: 10px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
}
.introich{
    width: 95%;
    margin: auto;
    padding: 10px;
}
.introich h1{
    font-size: 30px;
    font-family: "Arimo", sans-serif;
    font-weight: 550;
}
.introich h3{
    font-size: 20px;
    font-family: "Arimo", sans-serif;
    font-weight: 550;
}
@media (max-width:730px) {
    .df1 h1{
        font-size: 25px;
    }
}
@media (max-width:610px) {
    .df1 h1{
        font-size: 25px;
    }
    .fast{
        display: grid;
        grid-template-columns: 1fr ;
    }
    .online{
        width: 60%;
        margin-bottom: 20px;
    }
}
@media (max-width:570px) {
    .df1{
        display: grid;
        gap: 10px;
        grid-template-columns: 1fr;

    }
}
@media (max-width:520px) {
    .df1{
        display: grid;
        gap: 10px;
        grid-template-columns: 1fr;

    }
    .request{
        text-align: center;
        font-size: 30px;
        margin-top: 50px;
        font-weight: 500;
    }
}
@media (max-width:470px){
    .black h1{
        font-size: 35px;
        text-align: center;
        
    }
    .black h1 span{
        font-size: 20px;
    }
}
@media (max-width:450px) {
    .df1 h1{
        font-size: 20px;
    }
}
@media (max-width:430px) {
    .introich h1{
        font-size: 25px;
    }
    .request{
        text-align: center;
        font-size: 25px;
        margin-top: 50px;
        font-weight: 500;
    }
}
@media (max-width:390px){
    .black h1{
        font-size: 30px;
        text-align: center;
        
    }
    .black h1 span{
        font-size: 15px;
    }
}
@media (max-width:340px) {
    .introich h1{
        font-size: 20px;
    }
    .request{
        text-align: center;
        font-size: 22px;
        margin-top: 50px;
        font-weight: 500;
    }
}

.com{
    font-size: 30px;
    font-family: "Arimo", sans-serif;
    font-weight: 550;
}