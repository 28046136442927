.forgot-password-big-div h5{
    color: #821213;
    font-family: "Arimo", sans-serif;  
    font-weight: 600;  
    font-size: 35px;
    text-align: center;
    padding-top: 100px;
}
.forgot-password-big-div h3{
    color: #000;
    font-family: "Arimo", sans-serif;  
    font-weight: 600;  
    text-align: center;
    width: 80%;
    margin: auto;
}

.send-email-for-resent-forgotpassword{
    width: 95%;
    margin: auto;
    margin-top: 50px;
}

.send-email-for-resent-forgotpassword p{
    margin-bottom: 0px;
}
.input-and-button-forgotpassword{
    display: flex;
    align-items: center;
}

.input-and-button-forgotpassword input{
    width: 50%;
    height: 40px;
    border: 1px solid #ddd;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    outline: none;
    padding-left: 10px;
}
.input-and-button-forgotpassword input:focus{
    box-shadow: 0px 0px 5px #007bff;
}
.input-and-button-forgotpassword button{
    width: 50%;
    height: 40px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border: none;
    color: #fff;
    background-color: #508616;
}
.input-and-button-forgotpassword button:hover{
    background-color: #65af18;
}
.forgot-password-big-div h4{
    font-family: "Arimo", sans-serif;  
    font-weight: 600; 
    color: #333;    
    font-size: 18px;
    width: 95%;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 50px;
}
.forgot-password-big-div b{
    color: #821213;
}
@media (max-width:600px) {
    .input-and-button-forgotpassword{
        display: block;
    }
    .input-and-button-forgotpassword input{
        width: 100%;
        border-bottom-left-radius: 0px;
        border-top-right-radius: 10px;
    }
    .input-and-button-forgotpassword button{
        width: 100%;
        border-top-right-radius: 0px;
        border-bottom-left-radius: 10px;
    }
}

.forgot-password-big-div h6{
    width: 95%;
    margin: auto;
    margin-top: 30px;
    font-family: "Arimo", sans-serif;  
    font-weight: 600; 
    color: #333;    
}