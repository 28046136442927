*{
    box-sizing: border-box;
}

.header-auction{
    background: url('../img/auction.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 500px;
    text-align: center;
    color: #fff;
}
.header-auction h1{
    padding-top: 130px;
    font-family: "Arimo", sans-serif; 
    font-weight: 600;
}
.header-auction h3{
    font-family: "Arimo", sans-serif; 
    font-weight: 600;
}
.header-auction img{
    width: 300px;
    margin-top: 20px;
}
.section-auction{
    margin-top: 50px;
    margin-bottom: 200px;
}

.big-div-auction{
    display: flex;
    align-items: start;
    gap: 80px;
    box-shadow: 0px 0px 5px #666666;
    width: 90%;
    margin: auto;
    margin-top: 20px;
    border-radius: 5px;
    padding: 10px 10px 15px 50px;
    cursor: pointer;
}
.first-onside-auction img{
    width: 250px;
}

.second-onside-auction{
    width: 100%;
}
.second-onside-auction h1{
    font-family: "Arimo", sans-serif; 
    font-weight: 600;
    font-size: 23px;
}
.second-onside-auction p{
    font-family: "Arimo", sans-serif; 
    font-weight: 600;
    font-size: 17px;
    margin-bottom: 0;
    margin-top: 30px;
}
.second-onside-auction span{
    font-family: "Arimo", sans-serif; 
    font-weight: 600;
    font-size: 14px;
}
.df-little-div-auction{
    display: flex;
    align-items: flex-end;
    gap: 20px;
    justify-content: flex-end;
}
.df-little-div-auction p{
    color: #821213;
}

.okclassic-auction{
    width: 200px !important;
}
.macauction-auction{
    margin-top: 50px;
}

@media (max-width:900px) {
    .big-div-auction{
        display: flex;
        align-items: start;
        gap: 50px;
        box-shadow: 0px 0px 5px #666666;
        width: 90%;
        margin: auto;
        margin-top: 20px;
        border-radius: 5px;
        padding: 10px 10px 15px 50px;
        cursor: pointer;
    }
    .second-onside-auction h1{
        font-family: "Arimo", sans-serif; 
        font-weight: 600;
        font-size: 20px;
    }
    .second-onside-auction p{
        font-family: "Arimo", sans-serif; 
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 0;
        margin-top: 30px;
    }
    .second-onside-auction span{
        font-family: "Arimo", sans-serif; 
        font-weight: 600;
        font-size: 11px;
    }
}
@media (max-width:800px) {
    .big-div-auction{
        padding: 10px 10px 15px 10px;
    }
    .first-onside-auction img{
        width: 200px;
    }
    .classiccar-auction{
        margin-top: 40px;
    }
}
@media (max-width: 700px) {
    .big-div-auction{
        display: block;
    }   
    .first-onside-auction{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 15px;
    }
    .first-onside-auction img{
        width:  80%;
    }
    .second-onside-auction h1{
        font-family: "Arimo", sans-serif; 
        font-weight: 600;
        font-size: 23px;
    }
    .second-onside-auction p{
        font-family: "Arimo", sans-serif; 
        font-weight: 600;
        font-size: 17px;
        margin-bottom: 0;
        margin-top: 30px;
    }
    .second-onside-auction span{
        font-family: "Arimo", sans-serif; 
        font-weight: 600;
        font-size: 14px;
    }
    .macauction-auction{
        margin-top: 0px;
    }
    .classiccar-auction{
        margin-top: 0px ;
    }
}
@media (max-width: 500px) {
    .big-div-auction{
        display: block;
    }   
    .first-onside-auction{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 15px;
    }
    .first-onside-auction img{
        width:  80%;
    }
    .second-onside-auction h1{
        font-family: "Arimo", sans-serif; 
        font-weight: 600;
        font-size: 20px;
    }
    .second-onside-auction p{
        font-family: "Arimo", sans-serif; 
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 0;
        margin-top: 30px;
    }
    .second-onside-auction span{
        font-family: "Arimo", sans-serif; 
        font-weight: 600;
        font-size: 11px;
    }
    .macauction-auction{
        margin-top: 0px;
    }
    .classiccar-auction{
        margin-top: 0px ;
    }
    .df-little-div-auction{
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        flex-wrap: wrap;
    }
    .df-little-div-auction p{
        margin-bottom: 0px;
        margin-top: -0px;
    }
}