
*{
    box-sizing: border-box;
}
.instead-of-navbar{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
}
.instead-of-navbar-img{
    width: 60%;
}
.instead-of-navbar-text{
    display: flex;
    align-items: center;
    gap: 30px;
    width: 40%;
}
.instead-of-navbar-text p{
    width: 90%;
    font-family: "Arimo", sans-serif; 
    font-weight: 600;
    font-size: 14px;
}
.instead-of-navbar-text b{
    color: #821213;
    cursor: pointer;
}
.instead-of-navbar-text1{
    width: 10% !important; 
    color: #821213;
    font-family: "Arimo", sans-serif; 
    font-weight: 600;
}
@media (max-width:750px) {
    .instead-of-navbar{
        display: block;
        text-align: center;
    }
    .instead-of-navbar-img{
        width: 100%;
    }
    .instead-of-navbar-text{
        width: 100%;
        padding: 10px;
    }
    .instead-of-navbar-text p{
        width: 100%;
    }
    .instead-of-navbar-text1{
        display: none;
    }
}
@media (max-width:500px) {
   .instead-of-navbar-img img{
    width: 320px;
   } 
}
.big-div-addlisting-for-text{
    margin-top: 50px;
    padding: 10px;
}
.big-div-addlisting-for-text h1{
    color: #821213;
    font-family: "Arimo", sans-serif; 
    font-weight: 600;
    text-align: center;
    font-size: 40px;
}
.big-div-addlisting-for-text h3{
    color: #000000;
    font-family: "Arimo", sans-serif;
    text-align: center;
    font-weight: 550;
}

.account-information-div-add-listing{
    width: 95%;
    margin: auto;
    box-shadow: 0px 0px 5px #666666;
    border-radius: 10px;
    height: auto;
    margin-top: 30px;
}

.second-account-information-div-add-listing{
    display: flex;
    width: 100%;
    padding: 20px 20px 0px 50px;
}
.first-inside-account-information-div-add-listing{
    width: 100%;
    margin-bottom: 50px;
}
.first-inside-account-information-div-add-listing p{
    font-family: "Arimo", sans-serif;
    font-weight: 550;
    font-size: 15px;
    color: #666666;
    margin-bottom: 0;
}
.first-inside-account-information-div-add-listing h3{
    font-family: "Arimo", sans-serif;
    font-weight: 550;
    color: #821213;
    font-size: 25px;
}
.inside-account-information-div-add-listing{
    width: 50%;
    margin-top: 20px;
}
.first-account-information-div-add-listing{
    width: 100%;
    background-color: #821213;
    color: #fff;
    font-family: "Arimo", sans-serif;
    font-weight: 550;
    height: 35px;
    display: flex;
    padding: 5px;
    padding-left: 20px !important;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
@media (max-width:1050px) {
    .inside-account-information-div-add-listing{
        width: 40%;
        margin-top: 20px;
    }
}
@media (max-width: 900px) {
    .inside-account-information-div-add-listing{
        width: 30%;
        margin-top: 20px;
    }
    .second-account-information-div-add-listing{
        display: flex;
        width: 100%;
        padding: 20px 20px 20px 20px;
    }
}
@media (max-width:650px) {
    .second-account-information-div-add-listing{
        display: block;
    }
    .first-inside-account-information-div-add-listing{
        margin-bottom: 20px;
    }
}
@media (max-width:450px) {
    .first-inside-account-information-div-add-listing h3{
        font-size: 20px;
    }
    .first-inside-account-information-div-add-listing p{
        width: 100% !important;
    }
    .second-account-information-div-add-listing{
        padding: 5px;
    }
}


.second-input-information-div-add-listing{
    display: flex;
    gap: 2%;
    width: 100%;
    padding: 20px;
}

.inside-input-acc-div-add-listing{
    width: 48%;
}
.first-inside-input-acc-div-add-listing p{
    font-family: "Arimo", sans-serif;
    font-weight: 550;
    font-size: 17px;
    margin-bottom: 0;
}
.first-inside-input-acc-div-add-listing input{
    border: 1px solid #ddd;
    border-left: 2px solid #821213;
    border-radius: 5px;
    width: 100%;
    outline: none;
    padding-left: 20px;
    height: 35px;
    color: #666;
}
.first-inside-input-acc-div-add-listing input:focus{
    box-shadow: 0px 0px 5px #007bff;
}
.first-inside-input-acc-div-add-listing select{
    border: 1px solid #ddd;
    border-radius: 5px;
    width: 100%;
    outline: none;
    padding-left: 20px;
    height: 35px;
    color: #666;
}
.first-inside-input-acc-div-add-listing select:focus{
    box-shadow: 0px 0px 5px #007bff;
}
.first-inside-input-acc-div-add-listing{
    margin-top: 20px;
}
.third-button-information-div-add-listing{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}
.third-button-information-div-add-listing button{
    border: none;
    width: 200px;
    height: 40px;
    border-radius: 5px;
    background-color: #821213;
    color: #fff;
    font-family: "Arimo", sans-serif;
    font-weight: 600;
    margin-bottom: 30px;
}
.third1-button-information-div-add-listing{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}
.third1-button-information-div-add-listing button{
    border: none;
    width: 200px;
    height: 40px;
    border-radius: 5px;
    background-color: #821213;
    color: #fff;
    font-family: "Arimo", sans-serif;
    font-weight: 600;
    margin-bottom: 30px;
}
.third2-button-information-div-add-listing{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}
.third2-button-information-div-add-listing button{
    border: none;
    width: 200px;
    height: 40px;
    border-radius: 5px;
    background-color: #821213;
    color: #fff;
    font-family: "Arimo", sans-serif;
    font-weight: 600;
    margin-bottom: 30px;
}
.third3-button-information-div-add-listing{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}
.third3-button-information-div-add-listing button{
    border: none;
    width: 200px;
    height: 40px;
    border-radius: 5px;
    background-color: #821213;
    color: #fff;
    font-family: "Arimo", sans-serif;
    font-weight: 600;
    margin-bottom: 30px;
}

.fourth-input-information-div-add-listing{
    padding-left: 20px;
    padding-right: 20px;
}
.fourth-input-information-div-add-listing p{
    font-family: "Arimo", sans-serif;
    font-weight: 550;
    font-size: 17px;
    margin-bottom: 0;
}
.fourth-input-information-div-add-listing textarea{
    width: 100%;
    padding: 20px;
    border-radius: 5px;
    min-height: 300px;
    border: 1px solid #ddd;
    outline: none;
}

.fourth-input-information-div-add-listing textarea:focus{
    box-shadow: 0px 0px 5px #007bff; 
}

@media (max-width:650px) {
    .second-input-information-div-add-listing{
        display: block;
    }
    .inside-input-acc-div-add-listing{
        width: 100%;
    }
}


.inside-add-photo-acc-div-add-listing{
    text-align: center;
    width: 30%;
    box-shadow: 0px 0px 5px #666;
    border-radius: 10px;
    margin-top: 20px;
    padding: 15px;
    min-height: 200px;
    max-height: 250px;
    background: linear-gradient(180deg, #f2f2f2 50% , #fff 100% );
}
.inside-add-photo-acc-div-add-listing h5{
    color: #821213;
    font-family: "Arimo", sans-serif;
    font-weight: 550;
    margin-bottom: 20px;
}

.inside-add-photo-acc-div-add-listing h4{
    color: #fff;
    background-color: #006b81 ;
    font-family: "Arimo", sans-serif;
    font-weight: 550;
    font-size: 13px;
    width: 25px;
    height: 25px;
    padding: 5px;
    border-radius: 5px;
    display: flex;
    align-items: center;justify-content: center;
    margin: auto;
    margin-bottom: 20px;
}
.inside-add-photo-acc-div-add-listing p{
    font-family: "Arimo", sans-serif;
    font-size: 13px;
    font-weight: 550;
    width: 80%;
    margin: auto;
}
.inside-add-photo-acc-div-add-listing b{
    color: #821213;
}
.inside-add-photo-acc-div-add-listing h6{
    color: #fff;
    background-color: #09f ;
    font-family: "Arimo", sans-serif;
    font-weight: 550;
    font-size: 13px;
    width: 25px;
    height: 25px;
    padding: 5px;
    border-radius: 50%;
    display: flex;
    align-items: center;justify-content: center;
    margin: auto;
    margin-bottom: 20px;
}
.inside-add-photo-acc-div-add-listing h3{
    color: #09f;
    font-family: "Arimo", sans-serif;
    font-weight: 550;
    font-size: 20px;
    margin-bottom: 20px;
}
.inside-add-photo-acc-div-add-listing span{
    color: #821213;
    cursor: pointer;width: 80%;
    margin: auto;
    font-family: "Arimo", sans-serif;
    font-weight: 550;
}
.inside-add-photo-acc-div-add-listing span:hover{
    color: #ca171a;
}
.second-add-photo-information-div-add-listing{
    display: flex;
    align-items: center;
    justify-content: space-around;
}
@media (max-width:800px) {
    .second-add-photo-information-div-add-listing{
        display: block;
        padding: 20px;
    }
    .inside-add-photo-acc-div-add-listing{
        text-align: center;
        width: 100%;
        box-shadow: 0px 0px 5px #666;
        border-radius: 10px;
        margin-top: 20px;
        padding: 15px;
        min-height: 200px;
        max-height: 300px;
    }
}

.fourth-add-photo-information-div-add-listing{
    padding: 20px;
}
.fourth-add-photo-information-div-add-listing p{
    font-family: "Arimo", sans-serif;
    font-weight: 550;
}
.info-div-about-image-add-listing h6{
    font-weight: 600;
}
.info-div-about-image-add-listing{
    display: flex;
    font-family: "Arimo", sans-serif;
    align-items: start;
    gap: 10px;
    background: linear-gradient(180deg, #0868ce 60%, #09f 100%);
    color: #fff;
    border-radius: 5px;
    padding: 10px;
}
.place-for-image-looking-add-listing{
    min-height: 30px;
    height: auto;
    background-color: #f2f2f2;
    width: 100%;
    border-radius: 5px;
    margin-top: 10px;
    border: 1px solid #ddd;
}

.fifth-add-photo-information-div-add-listing{
    width: 100%;
    padding: 20px;
    text-align: center;
    font-family: "Arimo", sans-serif;
}
.fifth-add-photo-information-div-add-listing h5{
    font-weight: 600;
    margin-bottom: 30px;
}

.sixth-add-photo-information-div-add-listing{
    padding: 20px;
    position: relative;
}
.sixth-add-photo-information-div-add-listing input{
    position: absolute;
    width: 100%;
    height: 250px;
}
.inside-sixth-add-photo-information-div-add-listing{
    background-color: #ddd;
    height: 250px;
    border: 5px dashed #ccc;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.inside-sixth-add-photo-information-div-add-listing p{
    color: #821213;
    font-family: "Arimo", sans-serif;
    font-weight: 600;
    font-size: 20px;
    text-align: center;
}
.inside-sixth-add-photo-information-div-add-listing h2{
    color: #821213;
    font-family: "Arimo", sans-serif;
    font-weight: 600;
}


.for-display-block-and-none-addlist{
    display: none;
}
.for-display-block-and-none-addlist1{
    display: none;
}
.for-display-block-and-none-addlist2{
    display: none;
}
.for-display-block-and-none-addlist3{
    margin-bottom: 20px;
}

.for-alittle-bitten-info-next-page{
    width: 95%;
    margin: auto;
    height: 40px;
    background-color: #ccc;
    margin-top: 10px;
    margin-bottom: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px #666;
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 15px;
}
.for-alittle-bitten-info-next-page p{
    margin-bottom: 0px !important;
    margin-top: 5px;
    font-family: "Arimo", sans-serif;
    font-weight: 600;
}
.for-alittle-bitten-info-next-page h6{
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    font-family: "Arimo", sans-serif;
    font-weight: 600;
}

.for-alittle-bitten-info-next-page1{
    width: 95%;
    margin: auto;
    height: 40px;
    background-color: #ccc;
    margin-top: 10px;
    margin-bottom: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px #666;
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 15px;
}
.for-alittle-bitten-info-next-page1 p{
    margin-bottom: 0px !important;
    margin-top: 5px;
    font-family: "Arimo", sans-serif;
    font-weight: 600;
}
.for-alittle-bitten-info-next-page1 h6{
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    font-family: "Arimo", sans-serif;
    font-weight: 600;
}


.for-alittle-bitten-info-next-page2{
    width: 95%;
    margin: auto;
    height: 40px;
    background-color: #ccc;
    margin-top: 10px;
    margin-bottom: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px #666;
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 15px;
}
.for-alittle-bitten-info-next-page2 p{
    margin-bottom: 0px !important;
    margin-top: 5px;
    font-family: "Arimo", sans-serif;
    font-weight: 600;
}
.for-alittle-bitten-info-next-page2 h6{
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    font-family: "Arimo", sans-serif;
    font-weight: 600;
}

.for-display-block-and-none-addlist4{
    display: none;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
}
.for-display-block-and-none-addlist4 button{
    background: #4b8212;
    width: 300px;
    height: 50px;
    border-radius: 5px;
    color: #fff;
    border: none;
    font-family: "Arimo", sans-serif;
    font-weight: 600;
    font-size: 20px;
}