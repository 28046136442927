*{
    box-sizing: border-box;
}
.navbar-in-pay-for-add{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin: auto;
}
.navbar-in-pay-for-add img{
    width: 250px;
}
.navbar-in-pay-for-add p{
    color: #821213;
}
.header-in-pay-div h1{
    color: #821213;
    font-family: "Arimo", sans-serif; 
    font-weight: 600;
    text-align: center;
    font-size: 35px;
    padding-top: 50px;
}
.header-in-pay-div h3{
    color: #000;
    font-family: "Arimo", sans-serif; 
    font-weight: 600;
    text-align: center;
    font-size: 30px;
    margin-bottom: 0;
    display: flex;
    align-items: center;justify-content: center;
    gap: 10px;
}
.big-pay-div{
    width: 95%;
    margin: auto;
    margin-top: 30px;
    height: auto;
    box-shadow: 0px 0px 5px #666666;
    border-radius: 10px;
    overflow: hidden;
}
.table-in-pay{
    width: 100%;
}
.table-in-pay tr{
    border-bottom: 1px solid #ddd;
    height: 60px;
    padding: 10px;
    font-family: "Arimo", sans-serif; 
}
.table-in-pay th{
    color: #821213;
    padding: 10px;
}
.table-in-pay td{
    padding: 10px;
}
.quantity-table-pay{
    width: 10% ;
}
.description-table-pay{
    width: 40% ;
}
.price-table-pay{
    width: 23% ;
}
.button-in-table-pay{
    width: 100px;
    height: 35px;
    background-color: #006b81;
    border: none;
    border-radius: 5px;
    color: #fff;
    font-weight: 600;
    font-family: "Arimo", sans-serif; 
}
.button-in-table-pay:hover{
    background-color: #05a8c9;
}
.button-in-table-pay1{
    width: 200px;
    height: 35px;
    background-color: #821213;
    border: none;
    border-radius: 5px;
    color: #fff;
    font-weight: 600;
    font-family: "Arimo", sans-serif; 
}
.button-in-table-pay1:hover{
    background-color: #c71a1d;
}
.for-special-button-pay-div{
    display: flex;
    align-items: center;
    gap: 15px;
}
.your-item-pay{
    width: 100%;
    height: 40px;
    background-color: #821213;
    display: flex;
    align-items: center;
    color: #fff;
    font-family: "Arimo", sans-serif; 
    padding-left: 10px;
}
.your-item-pay p{
    margin-bottom: 0;
    font-weight: 600;
}